/*
 * Copyright 2023-2024 NXP
 */

import React from "react";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import styles from "./styles";

const CircularProgressStyled = (props: CircularProgressProps) => {
  return (
    <Box sx={styles.spinnerContainer}>
      <CircularProgress sx={styles.spinner} {...props}/>
    </Box>
  );
};
export default CircularProgressStyled;
