/*
 * Copyright 2023-2024 NXP
 */
"use client";

import styles from "@/src/components/styles";
import CircularProgressStyled from "@/src/components/UI/CircularProgressStyled/CircularProgressStyled";
import React from "react";
import { Backdrop } from "@mui/material";

export default function Loading() {
  return (
    <Backdrop data-testid="page-loading-spinner" open invisible>
      <CircularProgressStyled size={70} thickness={1.5} sx={styles.spinner} />
    </Backdrop>
  );
}
