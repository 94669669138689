/*
 * Copyright 2023-2024 NXP
 */

import COLORS from "@/src/lib/colors";

const styles = {
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  spinner: {
    color: COLORS.teal[400],
  },
};

export default styles;
